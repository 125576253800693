var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{class:{
        'pa-0': true,
        'inner-footer': true,
        'drawer-pad': _vm.brkPoints['brk-md-and-up'] && _vm.layout.drawer && _vm.layout.configuration.footerType !== 'floating',
    },attrs:{"fixed":_vm.isFixed}},[_c('v-content',{staticClass:"footer-container pt-0"},[(
                _vm.enrollment.path === 'patient-transition' &&
                    (_vm.$route.path.includes('transition') || _vm.$route.path.includes('rejection')) &&
                    _vm.enrollment.showTransitionGrayBox
            )?_c('TransitionBox'):_vm._e(),_vm._v(" "),(_vm.footerInfoLinkLocation === 'top')?_c('v-row',{class:{
                'footer-top': true,
            },attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('a',{attrs:{"data-test":"link_footer_privacy","href":"https://www.us.sandoz.com/privacy-policy","target":"_blank"}},[_vm._v("Privacy Policy")])])],1):_vm._e(),_vm._v(" "),(_vm.footerInfoLinkLocation === 'top')?_c('v-row',{class:{
                'footer-top': true,
            },attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c('span',[_c('strong',[_vm._v("\n                        Please see the full\n                        "),_c('a',{staticClass:"link-color",attrs:{"data-test":"link_footer_prescribing_info","href":"https://url.us.m.mimecastprotect.com/s/2DgsC68xnEcy0D2ZTpxGcx?domain=dailymed.nlm.nih.gov%22https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=1ac7d061-3380-468c-b077-c05f8dfbc829","target":"_blank"}},[_vm._v("\n                            Prescribing Information")]),_vm._v(", including Boxed Warning, and Medication Guide for HYRIMOZ (adalimumab-adaz).\n                    ")])])])],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"footer-bottom",attrs:{"order":_vm.footerInfoLinkLocation === 'top' ? 1 : 0,"justify":"center"}},[_c('v-col',{attrs:{"cols":"11","lg":"11"}},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.layout.configuration.showFooterLogo)?_c('img',{staticClass:"footer-logo",attrs:{"id":"footer-logo","src":_vm.footerLogoSrc,"alt":((_vm.$settings.name) + " logo")}}):_vm._e()]),_vm._v(" "),_c('v-row',{class:{ 'text-center': _vm.brkPoints['brk-sm-and-down'] || _vm.isTopOrBottomBar },attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-0",class:{
                            'text-right': _vm.brkPoints['brk-lg-only'] && _vm.footerInfoLinkLocation === 'left',
                            'text-left': _vm.brkPoints['brk-lg-only'] && _vm.footerInfoLinkLocation === 'right',
                        },attrs:{"cols":"12","md":"12","offset":"0","offset-lg":_vm.footerInfoLinkLocation === 'left' ? 1 : 0,"order":_vm.footerInfoLinkLocation === 'left' ? 1 : 0,"lg":"12"}},[_c('p',[_vm._v("\n                            All other trade names and trademarks are the property of their respective owners.\n                        ")]),_vm._v(" "),(
                                _vm.enrollment.path === 'patient-transition' &&
                                    (_vm.$route.path.includes('transition') || _vm.$route.path.includes('rejection'))
                            )?_c('p',[_vm._v("\n                            All third-party trade names and trademarks are the property of their respective owners.\n                        ")]):_vm._e(),_vm._v(" "),_c('p',[_vm._v("\n                            © 2024 Sandoz Inc., 100 College Road West, Princeton, NJ 08540.\n                            "),_c('nobr',[_vm._v("All rights reserved.")])],1),_vm._v(" "),_c('p',[_vm._v("MLR-0122-US     09/2024")])]),_vm._v(" "),(_vm.isLeftOrRightBar)?_c('v-col',{class:{
                            'text-right': _vm.brkPoints['brk-lg-only'] && _vm.footerInfoLinkLocation === 'right',
                            'text-left': _vm.brkPoints['brk-lg-only'] && _vm.footerInfoLinkLocation === 'left',
                        },attrs:{"order":_vm.footerInfoLinkLocation === 'left' ? 0 : 1,"align-self":"start","cols":"12","md":"5","lg":"5","offset":"0","offset-lg":_vm.footerInfoLinkLocation === 'left' ? 0 : 1}},[_c('ul',{staticClass:"footer-info-links"},[_c('li',[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Terms & Conditions")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Privacy Policy")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Important Safety Information")])])])]):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.footerInfoLinkLocation === 'bottom')?_c('v-row',{staticClass:"footer-top",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"t ext-center",attrs:{"cols":"12","md":"6"}},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Important Safety Information")]),_vm._v("\n                |\n                "),_c('a',{attrs:{"href":"#","target":"_blank"}},[_vm._v("Terms & Conditions")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }