import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1bf68744 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _4230f457 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _5325bf6a = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _e8cd0b4a = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _da237a88 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _33487edf = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _15a2d7f6 = () => interopDefault(import('..\\pages\\request-services.vue' /* webpackChunkName: "pages_request-services" */))
const _87196844 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _ab39a488 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _91029702 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _90e66800 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _90ae09fc = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _9091dafa = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _27362080 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _5dbb2fee = () => interopDefault(import('..\\pages\\patient\\authorization.vue' /* webpackChunkName: "pages_patient_authorization" */))
const _dd9e877c = () => interopDefault(import('..\\pages\\patient\\eligibility.vue' /* webpackChunkName: "pages_patient_eligibility" */))
const _4259d06b = () => interopDefault(import('..\\pages\\patient\\injection-training.vue' /* webpackChunkName: "pages_patient_injection-training" */))
const _5e39144f = () => interopDefault(import('..\\pages\\patient\\insurance.vue' /* webpackChunkName: "pages_patient_insurance" */))
const _547d392e = () => interopDefault(import('..\\pages\\patient\\insurance-information.vue' /* webpackChunkName: "pages_patient_insurance-information" */))
const _5eb66b58 = () => interopDefault(import('..\\pages\\patient\\more-service.vue' /* webpackChunkName: "pages_patient_more-service" */))
const _768c9dc8 = () => interopDefault(import('..\\pages\\patient\\nurse-ambassador.vue' /* webpackChunkName: "pages_patient_nurse-ambassador" */))
const _6d2b8ace = () => interopDefault(import('..\\pages\\patient\\patient-information.vue' /* webpackChunkName: "pages_patient_patient-information" */))
const _2cb39c42 = () => interopDefault(import('..\\pages\\patient\\prescriber-information.vue' /* webpackChunkName: "pages_patient_prescriber-information" */))
const _256e3601 = () => interopDefault(import('..\\pages\\patient\\selection.vue' /* webpackChunkName: "pages_patient_selection" */))
const _3ef0bc79 = () => interopDefault(import('..\\pages\\patient\\services.vue' /* webpackChunkName: "pages_patient_services" */))
const _460dc718 = () => interopDefault(import('..\\pages\\patient\\sharps-container.vue' /* webpackChunkName: "pages_patient_sharps-container" */))
const _00117790 = () => interopDefault(import('..\\pages\\patient\\success.vue' /* webpackChunkName: "pages_patient_success" */))
const _d6feb8e0 = () => interopDefault(import('..\\pages\\pharmacy\\eligibility.vue' /* webpackChunkName: "pages_pharmacy_eligibility" */))
const _7c06d6fc = () => interopDefault(import('..\\pages\\pharmacy\\insurance-information.vue' /* webpackChunkName: "pages_pharmacy_insurance-information" */))
const _3cb9ba6a = () => interopDefault(import('..\\pages\\pharmacy\\ncpdp.vue' /* webpackChunkName: "pages_pharmacy_ncpdp" */))
const _0a861832 = () => interopDefault(import('..\\pages\\pharmacy\\patient-information.vue' /* webpackChunkName: "pages_pharmacy_patient-information" */))
const _4b0b0286 = () => interopDefault(import('..\\pages\\pharmacy\\success.vue' /* webpackChunkName: "pages_pharmacy_success" */))
const _8a5395d6 = () => interopDefault(import('..\\pages\\provider\\diagnosis-information.vue' /* webpackChunkName: "pages_provider_diagnosis-information" */))
const _5536c612 = () => interopDefault(import('..\\pages\\provider\\eligibility.vue' /* webpackChunkName: "pages_provider_eligibility" */))
const _b0095c5a = () => interopDefault(import('..\\pages\\provider\\health-insurance-information.vue' /* webpackChunkName: "pages_provider_health-insurance-information" */))
const _4d1d3efe = () => interopDefault(import('..\\pages\\provider\\insurance-information.vue' /* webpackChunkName: "pages_provider_insurance-information" */))
const _06f17469 = () => interopDefault(import('..\\pages\\provider\\patient-information.vue' /* webpackChunkName: "pages_provider_patient-information" */))
const _41b1f1b9 = () => interopDefault(import('..\\pages\\provider\\pharmacy-prescription.vue' /* webpackChunkName: "pages_provider_pharmacy-prescription" */))
const _0506e60f = () => interopDefault(import('..\\pages\\provider\\prescriber-information.vue' /* webpackChunkName: "pages_provider_prescriber-information" */))
const _180c73d1 = () => interopDefault(import('..\\pages\\provider\\selection.vue' /* webpackChunkName: "pages_provider_selection" */))
const _dcaa93f0 = () => interopDefault(import('..\\pages\\provider\\success.vue' /* webpackChunkName: "pages_provider_success" */))
const _d3acd7aa = () => interopDefault(import('..\\pages\\provider\\summary.vue' /* webpackChunkName: "pages_provider_summary" */))
const _62fb5e6c = () => interopDefault(import('..\\pages\\provider\\upload-document.vue' /* webpackChunkName: "pages_provider_upload-document" */))
const _001c1037 = () => interopDefault(import('..\\pages\\provider\\upload-documents.vue' /* webpackChunkName: "pages_provider_upload-documents" */))
const _8db2f442 = () => interopDefault(import('..\\pages\\transition\\document-submission.vue' /* webpackChunkName: "pages_transition_document-submission" */))
const _7a659bae = () => interopDefault(import('..\\pages\\transition\\eligibility.vue' /* webpackChunkName: "pages_transition_eligibility" */))
const _682eee05 = () => interopDefault(import('..\\pages\\transition\\patient-information.vue' /* webpackChunkName: "pages_transition_patient-information" */))
const _7cfcb9a4 = () => interopDefault(import('..\\pages\\transition\\success.vue' /* webpackChunkName: "pages_transition_success" */))
const _a382c3b0 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _1bf68744,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _4230f457,
    children: [{
      path: "",
      component: _5325bf6a,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _e8cd0b4a,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _da237a88,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _33487edf,
    name: "rejection"
  }, {
    path: "/request-services",
    component: _15a2d7f6,
    name: "request-services"
  }, {
    path: "/unlock",
    component: _87196844,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _ab39a488,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _91029702,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _90e66800,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _90ae09fc,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _9091dafa,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _27362080,
    name: "errors-500"
  }, {
    path: "/patient/authorization",
    component: _5dbb2fee,
    name: "patient-authorization"
  }, {
    path: "/patient/eligibility",
    component: _dd9e877c,
    name: "patient-eligibility"
  }, {
    path: "/patient/injection-training",
    component: _4259d06b,
    name: "patient-injection-training"
  }, {
    path: "/patient/insurance",
    component: _5e39144f,
    name: "patient-insurance"
  }, {
    path: "/patient/insurance-information",
    component: _547d392e,
    name: "patient-insurance-information"
  }, {
    path: "/patient/more-service",
    component: _5eb66b58,
    name: "patient-more-service"
  }, {
    path: "/patient/nurse-ambassador",
    component: _768c9dc8,
    name: "patient-nurse-ambassador"
  }, {
    path: "/patient/patient-information",
    component: _6d2b8ace,
    name: "patient-patient-information"
  }, {
    path: "/patient/prescriber-information",
    component: _2cb39c42,
    name: "patient-prescriber-information"
  }, {
    path: "/patient/selection",
    component: _256e3601,
    name: "patient-selection"
  }, {
    path: "/patient/services",
    component: _3ef0bc79,
    name: "patient-services"
  }, {
    path: "/patient/sharps-container",
    component: _460dc718,
    name: "patient-sharps-container"
  }, {
    path: "/patient/success",
    component: _00117790,
    name: "patient-success"
  }, {
    path: "/pharmacy/eligibility",
    component: _d6feb8e0,
    name: "pharmacy-eligibility"
  }, {
    path: "/pharmacy/insurance-information",
    component: _7c06d6fc,
    name: "pharmacy-insurance-information"
  }, {
    path: "/pharmacy/ncpdp",
    component: _3cb9ba6a,
    name: "pharmacy-ncpdp"
  }, {
    path: "/pharmacy/patient-information",
    component: _0a861832,
    name: "pharmacy-patient-information"
  }, {
    path: "/pharmacy/success",
    component: _4b0b0286,
    name: "pharmacy-success"
  }, {
    path: "/provider/diagnosis-information",
    component: _8a5395d6,
    name: "provider-diagnosis-information"
  }, {
    path: "/provider/eligibility",
    component: _5536c612,
    name: "provider-eligibility"
  }, {
    path: "/provider/health-insurance-information",
    component: _b0095c5a,
    name: "provider-health-insurance-information"
  }, {
    path: "/provider/insurance-information",
    component: _4d1d3efe,
    name: "provider-insurance-information"
  }, {
    path: "/provider/patient-information",
    component: _06f17469,
    name: "provider-patient-information"
  }, {
    path: "/provider/pharmacy-prescription",
    component: _41b1f1b9,
    name: "provider-pharmacy-prescription"
  }, {
    path: "/provider/prescriber-information",
    component: _0506e60f,
    name: "provider-prescriber-information"
  }, {
    path: "/provider/selection",
    component: _180c73d1,
    name: "provider-selection"
  }, {
    path: "/provider/success",
    component: _dcaa93f0,
    name: "provider-success"
  }, {
    path: "/provider/summary",
    component: _d3acd7aa,
    name: "provider-summary"
  }, {
    path: "/provider/upload-document",
    component: _62fb5e6c,
    name: "provider-upload-document"
  }, {
    path: "/provider/upload-documents",
    component: _001c1037,
    name: "provider-upload-documents"
  }, {
    path: "/transition/document-submission",
    component: _8db2f442,
    name: "transition-document-submission"
  }, {
    path: "/transition/eligibility",
    component: _7a659bae,
    name: "transition-eligibility"
  }, {
    path: "/transition/patient-information",
    component: _682eee05,
    name: "transition-patient-information"
  }, {
    path: "/transition/success",
    component: _7cfcb9a4,
    name: "transition-success"
  }, {
    path: "/",
    component: _a382c3b0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
